<template>
	<div>
		<!-- create button to route create page -->
		<section
			class="limit-product-list-create-container text-right"
			data-test-id="limit-product-list-create-container"
		>
			<router-link
				:to="{ name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_CREATE }"
				class="btn btn-secondary mb-3"
			>
				<CIcon class="mr-2" name="cil-plus" />
				Limit Product
			</router-link>
		</section>

		<!-- filter section -->
		<section
			class="limit-product-list-filter-container"
			data-test-id="limit-product-list-filter-container"
		>
			<CRow>
				<CCol class="d-flex align-items-start">
					<form
						class="search-form flex-fill"
						@submit.prevent="handleSearch"
					>
						<CInput
							v-model.trim="queryParams.q"
							data-test-id="search-input"
							placeholder="Search by limit product name or ID"
						>
							<template #prepend-content>
								<CIcon
									name="cil-magnifying-glass"
									data-test-id="search-button"
								/>
							</template>
						</CInput>
					</form>
					<BaseFilterButton
						class="ml-3"
						data-test-id="filter-button"
						:is-open="isShowFilter"
						:has-filter="hasFilter"
						@onClick="toggleFilter"
					/>
				</CCol>
			</CRow>
		</section>

		<!-- toggle filter section -->
		<section
			class="limit-product-list-toggle-filter-container"
			data-test-id="limit-product-list-toggle-filter-container"
		>
			<transition name="fade">
				<div
					v-if="isShowFilter"
					class="filter-box rounded-sm mb-3"
				>
					<CRow>
						<CCol md="3">
							<BaseDropDown
								v-model="filter.status"
								:searchable="false"
								:options="LIMIT_PRODUCT_STATUS_OPTIONS"
								:allow-empty="false"
								class="select-custom"
								label="name"
								track-by="value"
								label-drop-down="Flash sale status"
								@input="handleChangeStatus"
							/>
						</CCol>
						<CCol md="3">
							<BaseInputDate
								:value="startDate"
								:max-date="endDate"
								label="Start date"
								placeholder="All date"
								@input="handleChangeStartDate"
							>
								<template #append-content>
									<CIcon class="cil-calendar" name="cil-calendar" />
								</template>
							</BaseInputDate>
						</CCol>
						<CCol md="3">
							<BaseInputDate
								:value="endDate"
								:min-date="startDate"
								label="End date"
								placeholder="All date"
								@input="handleChangeEndDate"
							>
								<template #append-content>
									<CIcon class="cil-calendar" name="cil-calendar" />
								</template>
							</BaseInputDate>
						</CCol>
						<CCol md="3">
							<BaseDropDown
								v-model="filter.period"
								:searchable="false"
								:options="LIMIT_PRODUCT_PERIODS_OPTIONS"
								:allow-empty="false"
								class="select-custom"
								label="name"
								track-by="value"
								label-drop-down="Period status"
								@input="handleChangePeriod"
							/>
						</CCol>
					</CRow>
				</div>
			</transition>
		</section>

		<!-- total item section -->
		<section
			class="limit-product-list-total-item-container"
			data-test-id="limit-product-list-total-item-container"
		>
			<BaseSearchFormFooter
				:count="0"
				append-text="list(s) found"
				@onReset="resetFilter"
			/>
		</section>

		<!-- list of limit product -->
		<main
			class="limit-product-list-container"
			data-test-id="limit-product-list-container"
		>
			<BaseTable
				:is-loading="isLoading"
				:pagination="{
					pages: meta.lastPage,
					activePage: meta.currentPage
				}"
				:fields="LIMIT_PRODUCT_TABLE_FIELDS"
				:items="list.data"
				clickable-rows
				striped
				:link-to="ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_EDIT"
				vertical-align="top"
				class="table-custom"
				@onPaginationClick="handlePageChange"
			>
				<template #no-items-view>
					<div class="empty-table-element">
						<p class="subtitle">
							{{
								$t('global.searchNotFound', {
									field: 'limit product',
								})
							}}
						</p>
					</div>
				</template>
				<template #isActive="{item}">
					<CBadge class="badge-status" :color="LIMIT_PRODUCT_COLOR.STATUS[item]">
						{{ LIMIT_PRODUCT_TITLE.STATUS[item] }}
					</CBadge>
				</template>
				<template #period="{item}">
					<CBadge class="badge-status" :color="LIMIT_PRODUCT_COLOR.PERIODS_STATUSES[item]">
						{{ LIMIT_PRODUCT_TITLE.PERIODS_STATUSES[item] }}
					</CBadge>
				</template>
			</BaseTable>
		</main>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ROUTE_NAME } from '../../enums/route';
import {
	cleanObject,
	cloneDeep, dateFormat,
	getBooleanQueryParameter,
	hasSearchFilter,
	randomSearchString,
} from '../../assets/js/helpers';
import {
	LIMIT_PRODUCT_PERIODS_OPTIONS,
	LIMIT_PRODUCT_STATUS_OPTIONS,
	LIMIT_PRODUCT_TABLE_FIELDS,
	LIMIT_PRODUCT_COLOR,
	LIMIT_PRODUCT_TITLE,
} from '../../enums/limitProduct';

export default {
	name: 'LimitProductList',
	data() {
		return {
			ROUTE_NAME,
			LIMIT_PRODUCT_STATUS_OPTIONS,
			LIMIT_PRODUCT_PERIODS_OPTIONS,
			LIMIT_PRODUCT_TABLE_FIELDS,
			LIMIT_PRODUCT_COLOR,
			LIMIT_PRODUCT_TITLE,

			// filter
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				is_active: getBooleanQueryParameter(this.$route.query.is_active),
				period_status: this.$route.query.period_status || null,
				start_date: this.$route.query.start_date || null,
				end_date: this.$route.query.end_date || null,
				r: randomSearchString(),
			},
			filter: {
				status: this.getSelectedValue(this.$route.query.is_active, LIMIT_PRODUCT_STATUS_OPTIONS),
				period: this.getSelectedValue(this.$route.query.period_status, LIMIT_PRODUCT_PERIODS_OPTIONS),
			},
			isShowFilter: false,
		};
	},
	computed: {
		...mapState('limitProduct', {
			list: 'list',
		}),

		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},

		// using in filter toggle section
		startDate() {
			if (!this.queryParams.start_date) {
				return null;
			}

			return new Date(this.queryParams.start_date);
		},
		endDate() {
			if (!this.queryParams.end_date) {
				return null;
			}

			return new Date(this.queryParams.end_date);
		},

		isLoading() {
			return this.list.isLoading;
		},

		meta() {
			return this.list.meta;
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;

		await this.getLimitProducts(this.queryParams);
	},
	methods: {
		...mapActions({
			getLimitProducts: 'limitProduct/getLimitProducts',
		}),

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		// using in filter section
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = {}) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || {
				name: null,
				value: null,
			};
		},


		// using in filter toggle section
		handleChangeStatus({ value }) {
			this.queryParams.page = null;
			this.queryParams.is_active = value;
			this.updateUrlParams();
		},

		handleChangeStartDate(value) {
			this.queryParams.page = null;
			this.queryParams.start_date = dateFormat(value);
			this.updateUrlParams();
		},

		handleChangeEndDate(value) {
			this.queryParams.page = null;
			this.queryParams.end_date = dateFormat(value);
			this.updateUrlParams();
		},

		handleChangePeriod(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},

		// total item section
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				is_active: null,
				period_status: null,
				start_date: null,
				end_date: null,
				r: null,
			};

			this.updateUrlParams();
		},

		// using in list of limit product
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
	},
};
</script>
